<template>
    <div id="bs-app">
        <div class="zcBottomSheet" v-if="isOpen">
            <div id="bt-content" :style="customStyle" :class="customClass" class="zcBottomSheet-content">
                <div class="d-flex justify-content-center mb-4">
                    <div @click="closeBottomSheet" style="width: 3.125rem;height: 0.25rem;background: rgb(217 217 220);">
                    </div>
                </div>
                <slot name="child"></slot>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "zcBottomSheet",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        customStyle: {
            type: String,
            default: '',
        },
        customClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {

        }
    },
    methods: {
        openBottomSheet() {

            this.$emit("open");
        },
        closeBottomSheet() {
            this.$emit("close");
        },
    },

};
</script>
  
<style>
.zcBottomSheet {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #0000003d;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zcBottomSheet-content {
    position: fixed;
    top: 25vh;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 2;
    padding: 1.875rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    overflow: auto;
}
</style>
