<template>
    <div class="w-100">
        <Header />
        <!-- <b-row class="home-frame1 fluid mt-6">
            <img src="@/assets/zaajira-candidate/assets/icons/Pattern1.png" width="100%" height="176px" class="img-fluid" />
            <div class="home-items">
                <p>Wednesday, August 1,2023</p>
                <h1>Welcome back, find your favourite job 🧑🏻‍💻</h1>
            </div>
        </b-row> -->
        <div class="search-container mt-6">
            <!-- Your content goes here -->
            <b-form @submit.prevent="jobSearch" style="width: 86%; position: relative;"
                class="customized-form d-flex zc-m-1inline">
                <img src="@/assets/zaajira-candidate/assets/icons/search-normal.svg" width="19px" height="19px"
                    class="img-fluid search-icon" />
                <b-form-input style="padding-left: 3rem; background: var(--white);" v-model="search"
                    class="form-control mr-sm-2 zc-search-input" size="sm"
                    :placeholder='$gettext("Search_by_title_company_or_keywords")'></b-form-input>
                <!-- <img id="filter-btn" @click="BottomSheetFilterToggler"
                    src="@/assets/zaajira-candidate/assets/icons/ion_filter.svg" width="25px" height="25px"
                    class="img-fluid is-mobile filter-icon" /> -->
            </b-form>
        </div>
        <div class="d-flex justify-content-between align-items-center  mr-3" style="cursor: pointer; margin-left:.9rem">
            <div @click="BottomSheetFilterToggler" class="is-mobile ml-4">
                <img src="@/assets/zaajira-candidate/assets/icons/ion_filter.svg" width="25px" height="25px"
                    style="padding-bottom:5px;" class="img-fluid" />
                &nbsp;
                <span style="font-size: 1.25rem;color: #007BFF;">Filter</span>
            </div>
            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <span>
                        <img src="@/assets/app-icons/sort-amount-down-alt-solid.svg" alt="" height="16px" width="16px"
                            :style="SortBy === 'desc' ? 'rotate:0deg;' : 'rotate:180deg;'" style="filter: opacity(0.5);" />
                        <span style="font-size: 1.25rem;color: #007BFF;">
                            {{ SortBy === 'desc' ? 'Latest' : 'Oldest' }}
                        </span>
                    </span>
                </template>
                <b-dropdown-item @click="sort('desc')">Latest</b-dropdown-item>
                <b-dropdown-item @click="sort('asc')">Oldest</b-dropdown-item>
            </b-dropdown>
        </div>
        <b-row class="p-3 m-0 ">
            <b-col class="filter-container is-desktop" col lg="3" md="12" sm="12">
                <div class="filter-header mt-2 d-flex justify-content-between">
                    <span>{{ $gettext("Filters") }}</span>
                    <span style="cursor: pointer;" @click.prevent.stop="clearFilter">
                        {{ $gettext("Clear_all") }}
                        <img src="@/assets/zaajira-candidate/assets/icons/coss_gray.svg" width="19px" height="19px"
                            class="img-fluid" />
                    </span>
                </div>
                <div class="py-2">
                    <label class="typo__label">{{ $gettext("Country") }}</label>
                    <multiselect class="custom-select-2" v-model="country" :options="countryOptions" :searchable="false"
                        @input="GetAllCityOfCountry(country.country_id)" :close-on-select="true" :show-labels="true"
                        label="country_name" placeholder="Pick a value">
                    </multiselect>
                </div>
                <div class="py-2">
                    <label class="typo__label">{{ $gettext("City") }}</label>
                    <multiselect class="custom-select-2" v-model="city" :options="cityOptions" :searchable="false"
                        :close-on-select="true" :show-labels="true" label="label" placeholder="Pick a value">
                    </multiselect>
                </div>
                <div class="py-2">
                    <label class="typo__label">{{ $gettext("Type_of_Job") }}</label>
                    <multiselect class="custom-select-2" v-model="typeOfJob" :options="typeOfJobOptions" :searchable="false"
                        :close-on-select="true" :show-labels="true" label="job_type_title" placeholder="Pick a value">
                    </multiselect>
                </div>
                <div class="pt-2">
                    <label class="typo__label">{{ $gettext("Industry") }}</label>
                    <!-- <multiselect class="custom-select-2" v-model="city" :options="cityOptions" :searchable="false"
                        :close-on-select="true" :show-labels="true" placeholder="Pick a value"></multiselect> -->
                    <div class="ind-filter-items">
                        <b-form-group style="height: 270px;overflow: auto;" v-slot="{ ariaDescribedby }">
                            <b-form-checkbox-group id="h-industry-filter" v-model="industry" :options="industryOptions"
                                value-field="speciality_id" text-field="speciality" :aria-describedby="ariaDescribedby"
                                name="flavour-1"></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="pt-2 w-100 d-flex justify-content-center align-items-center">
                    <button class="btn btn-user-profile4 btn-text-white w-100" @click="filterJobs">
                        {{ $gettext("Apply_Filter") }}
                    </button>
                </div>
            </b-col>
            <b-col col lg="9" md="12" sm="12">
                <!-- <b-form class="customized-form d-flex zc-m-1inline">
                    <img src="@/assets/zaajira-candidate/assets/icons/search-normal.svg" width="19px" height="19px"
                        class="img-fluid search-icon" />
                    <b-form-input style="padding-left: 2rem; background: var(--neutral-white);"
                        class="form-control mr-sm-2 zc-search-input" size="sm"
                        placeholder="Search by title, company or keywords.."></b-form-input>
                    <img @click="BottomSheetFilterToggler" src="@/assets/zaajira-candidate/assets/icons/ion_filter.svg"
                        width="25px" height="25px" class="img-fluid is-mobile filter-icon" />
                </b-form> -->

                <div id="h-userjob" class="h-user-jobs">
                    <div class="user-card zc-w-20 my-2 zc-m-1inline" v-for="( job, i ) in    userJobs   " :key="i">
                        <b-row>
                            <b-col col cols="12" lg="8" md="12" sm="12">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex justify-content-between">
                                        <div style="margin-top:.5rem ;">
                                            <b-avatar style="background: transparent;" rounded>
                                                <!-- <img :src="avtar"
                                                    width="75%" height="auto" class="img-fluid" /> -->
                                                <img :src="`${IMAGEURL}${job.company_logo_path}`" width="95%" height="95%"
                                                    style="border-radius: 10px;" />
                                            </b-avatar>
                                        </div>
                                        <div class="px-3 align-items-center">
                                            <span style="cursor: pointer;" @click="gotoJobDetail(job.job_id)">
                                                <router-link :to="`/candidate/job-deatils/${job.job_id}`"
                                                    class="h-job-name">
                                                    {{ job.Job_title }}
                                                </router-link>
                                            </span>
                                            <div style="gap: 10px;" class="d-flex">
                                                <span class="h-company">{{ job.company }}</span>
                                                <span class="h-part-time">{{ job.jobType }}</span>
                                                <!-- <span class="h-exp">{{ job.designation }}</span> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <ul class="mt-3">
                                    <!-- <li v-for="(desc, index) in job.jobDescription" :key="`${index}-dec`"
                                        class="h-job-description">
                                        {{ desc.description }}
                                    </li> -->
                                    <li class="h-job-description" v-for="( data, i ) in  job.jobDescription " :key="i">
                                        <!-- {{ job.jobDescription.split(".") }} -->
                                        {{ data }}
                                    </li>
                                </ul>
                            </b-col>

                            <b-col col cols="12" lg="4" md="12" sm="12" class="top-bar">
                                <b-row>
                                    <b-col col lg="12" md="6" sm="6" class="h-text-align">
                                        <div class="h-location">
                                            <img style="opacity: .5;"
                                                src="@/assets/zaajira-candidate/assets/icons/location.svg"
                                                class="img-fluid" />
                                            {{ job.country }}, {{ job.city }}
                                        </div>
                                        <div class="h-posted-at ml-md-1">
                                            {{ job.postedAt }}
                                        </div>
                                    </b-col>
                                    <b-col col lg="12" md="6" sm="6">
                                        <div class="mt-lg-3 h-opening">
                                            {{ job.numberOfOpenings }} Opening
                                        </div>
                                        <div class="h-currency">
                                            {{ job.currency }} {{ job.job_salary_range_from }}-{{ job.job_salary_range_to
                                            }}/M
                                            <!-- {{job.gender}} -->
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="infiniteScrollLoader" class="d-flex align-items-center justify-content-center">
                        <div class="infite-s-loader"> </div>
                    </div>
                    <div style="height:1rem;"></div>
                </div>
            </b-col>
        </b-row>
        <zcBottomSheet :isOpen="homeFilter && isMobileMenu" @open="BottomSheetFilterToggler"
            @close="BottomSheetFilterToggler">
            <div slot="child">
                <b-row class="m-0 ">
                    <b-col class="filter-container" col lg="3" md="12" sm="12">
                        <div class="filter-header mt-2 d-flex justify-content-between">
                            <span>{{ $gettext("Filters") }}</span>
                            <span style="cursor: pointer;" @click.prevent.stop="clearFilter">
                                {{ $gettext("Clear_all") }}
                                <img src="@/assets/zaajira-candidate/assets/icons/coss_gray.svg" width="19px" height="19px"
                                    class="img-fluid" />
                            </span>
                        </div>
                        <div class="py-2 text-left">
                            <label class="typo__label">
                                {{ $gettext("Country") }}
                            </label>
                            <multiselect class="custom-select-2" v-model="country" :options="countryOptions"
                                :searchable="false" @input="GetAllCityOfCountry(country.country_id)" :close-on-select="true"
                                :show-labels="true" label="country_name" placeholder="Pick a value">
                            </multiselect>
                        </div>
                        <div class="py-2 text-left">
                            <label class="typo__label">{{ $gettext("City") }}</label>
                            <multiselect class="custom-select-2" v-model="city" :options="cityOptions" :searchable="false"
                                :close-on-select="true" :show-labels="true" label="label" placeholder="Pick a value">
                            </multiselect>
                        </div>
                        <div class="py-2 text-left">
                            <label class="typo__label">{{ $gettext("Type_of_Job") }}</label>
                            <multiselect class="custom-select-2" v-model="typeOfJob" :options="typeOfJobOptions"
                                :searchable="false" :close-on-select="true" :show-labels="true" label="job_type_title"
                                placeholder="Pick a value">
                            </multiselect>
                        </div>
                        <div id="industry-label" class="pt-2 text-left">
                            <label class="typo__label">{{ $gettext("Industry") }}</label>
                            <!-- <multiselect class="custom-select-2" v-model="city" :options="cityOptions" :searchable="false"
                        :close-on-select="true" :show-labels="true" placeholder="Pick a value"></multiselect> -->
                            <div class="ind-filter-items">
                                <b-form-group style="height: 270px;overflow: auto;" v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox-group id="h-industry-filter" v-model="industry"
                                        value-field="speciality_id" text-field="speciality" :options="industryOptions"
                                        :aria-describedby="ariaDescribedby" name="industry"></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <div class="pt-2 w-100 d-flex justify-content-center align-items-center">
                        <button class="btn btn-user-profile4 btn-text-white w-100" @click="filterJobs">
                            {{ $gettext("Apply_Filter") }}
                        </button>
                    </div>
                </b-row>
            </div>
        </zcBottomSheet>
    </div>
</template>

<script>
import Header from '../../../components/Partials/Header.vue'
import zcBottomSheet from '../../../components/Modal/BottomSheet.vue'
import Multiselect from 'vue-multiselect'
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
import { formattedNumber, convertToTimeSince } from '../Helper/HelperFunctions.js'
import { IMAGEURL } from "@/config";
import avtar from '@/assets/zaajira-candidate/assets/bg-images/avtar-image.png'


export default {
    name: "HomePage",
    components: { Header, Multiselect, zcBottomSheet },
    data() {
        return {
            avtar,
            IMAGEURL,
            isLoggedIn: false,
            isMobileMenu: window.innerWidth <= 992,
            homeFilter: false,
            filters: [],
            SortBy: "desc",
            search: '',
            country: null,
            countryOptions: [],
            city: null,
            cityOptions: [],
            industry: [],
            industryOptions: [],
            userJobCount: null,
            currentpage: 1,
            perPage: 10,
            totalJobs: null,
            userJobs: [
                // {
                //     Job_title: "Senior ui designer",
                //     company: "Amazon",
                //     jobType: "Part-time",
                //     designation: "Senior level",
                //     country: "Pakistan",
                //     city: "Islamabad",
                //     postedAt: "Posted 1 hr ago",
                //     jobDescription: [
                //         {
                //             description: "Collaborate with the marketing team to optimize conversion.",
                //         },
                //         {
                //             description: " Develop inspiring, persuasive, and convincing copy for a wide array of writing needs.",
                //         }
                //     ],
                //     numberOfOpenings: 10,
                //     currency: " TZS 800,000 - 1,000,00",
                //     gender: "M"
                // },
            ],
            infiniteScrollLoader: false,
            isAllJobsFetched: false,
            typeOfJob: null,
            typeOfJobOptions: [
                {
                    job_type_id: 1,
                    job_type_title: "Any",
                },
                {
                    job_type_id: 2,
                    job_type_title: "Full Time",
                },
                {
                    job_type_id: 3,
                    job_type_title: "Part Time",
                },
                {
                    job_type_id: 4,
                    job_type_title: "Remote",
                },
                {
                    job_type_id: 5,
                    job_type_title: "Internship",
                },
                {
                    job_type_id: 6,
                    job_type_title: "Contract",
                },
                {
                    job_type_id: 7,
                    job_type_title: "Work from home",
                },
            ],
        }
    },
    methods: {
        gotoJobDetail(job_id) {
            //console.log(job_id);
            this.$router.push({ path: `/candidate/job-deatils/${job_id}`, params: { job_id } });
        },
        async getAllCountry() {
            // MasterServices.GetAllCountry()
            //     .then((res) => {
            ////         console.log("res.data.data", res.data.data);
            //         this.CountryOption = res.data.data.length ? res.data.data : []
            ////         console.log("this.CountryOption", this.CountryOption);

            //     })
            //     .catch((error) => {
            ////         console.log(error);
            //     })
            try {
                const AllCountryRes = await MasterServices.GetAllCountry()
                return AllCountryRes.data.data
            } catch (error) {
                //console.log(error);
            }
        },
        async GetAllCityOfCountry(country_id) {
            //console.log("this.country", country_id);
            if (country_id !== null) {
                const payload = {
                    country_id: country_id
                }
                // MasterServices.GetAllCityOfCountry(payload).then((res) => {
                ////     console.log('City::', res);
                //     this.CityOption = res.data.data.map((city) => {
                //         return {
                //             label: city.city_name,
                //             city_id: city.city_id,
                //         };
                //     });

                // });
                try {
                    const cityRes = await MasterServices.GetAllCityOfCountry(payload)
                    this.cityOptions = cityRes.data.data.map((city) => {
                        return {
                            label: city.city_name,
                            city_id: city.city_id,
                        };
                    });

                } catch (error) {
                    //console.log("city::error", error);
                }
            }
        },
        async getAllIndustry() {
            try {
                const AllIndustryRes = await MasterServices.GetAllIndustry()
                //console.log("AllIndustryRes", AllIndustryRes.data.data.types);
                return AllIndustryRes.data.data.types;
            } catch (error) {

            }
        },
        async GetMasterData() {
            this.countryOptions = await this.getAllCountry()
            this.industryOptions = await this.getAllIndustry()
        },
        async sort(s) {
            this.SortBy = s
            const loader = this.$loading.show()
            try {
                this.country = null
                this.city = null
                this.industry = []
                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: 1,
                    perPage: 10,
                    sort: s,
                    search: this.search,
                    filter: { filter: { filters: [] } }
                }
                await this.getAllJobsOrFilter(payload)
            } catch (error) {
                console.log(error);
            }
            loader.hide()
        },
        async getAllJobsOrFilter(payload) {

            // CandidateService.GetAllJobsOrFilter(payload)
            //     .then((res) => {
            ////         console.log("all jobs", res.data.data.data);
            //         this.userJobCount = res.data.data.total
            //         const allJobs = res.data.data.data
            //         let filterJob = []
            //         allJobs.map(async (aj) => {
            //             const apJob = {
            //                 company_logo_path: aj.company_logo_path,
            //                 Job_title: aj.job_title,
            //                 company: aj.company_long_name,
            //                 jobType: aj.job_type,
            //                 designation: "Senior level",
            //                 country: aj.country,
            //                 city: aj.city_name,
            //                 postedAt: "Posted " + convertToTimeSince(aj.job_start_date),
            //                 jobDescription: aj.job_description,
            //                 numberOfOpenings: aj.vacancy,
            //                 currency: aj.currency_code,
            //                 job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
            //                 job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
            //                 gender: aj.gender === "Female" ? "F" : "M"
            //             }
            //             filterJob.push(apJob)
            //         })
            //         this.userJobs = filterJob
            //         loader.hide()
            //     })
            //     .catch((error) => {
            ////         console.log(error);
            //         loader.hide()
            //     })

            try {
                const res = await CandidateService.GetAllJobsOrFilter(payload)
                console.log("all jobs", res.data.data.data);
                this.userJobCount = res.data.data.total
                this.totalJobs = res.data.data.total
                const allJobs = res.data.data.data
                let filterJob = []
                allJobs.map(async (aj) => {
                    const apJob = {
                        job_id: aj.job_id,
                        company_logo_path: aj.company_logo_path,
                        Job_title: aj.job_title,
                        company: aj.company_long_name,
                        jobType: aj.job_type,
                        designation: "Senior level",
                        country: aj.country_name,
                        city: aj.city_name,
                        postedAt: "Posted " + convertToTimeSince(aj.job_created_at),
                        jobDescription: aj.job_description.split(/[.•]/).filter((item, i) => item !== "" && item !== "\n" && i < 3),
                        numberOfOpenings: aj.vacancy,
                        currency: aj.currency_code,
                        job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
                        job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
                        gender: aj.gender === "Female" ? "F" : "M"
                    }
                    // console.log("apJob.jobDescription", apJob.jobDescription);
                    filterJob.push(apJob)
                })
                this.userJobs = filterJob


            } catch (error) {
                //console.log(error);

            }
        },

        async prefilledData() {
            const loader = this.$loading.show()
            try {
                await this.GetMasterData()
                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: this.currentpage,
                    perPage: this.perPage,
                    search: this.search,
                    filter: { filter: { filters: [] } }
                }
                await this.getAllJobsOrFilter(payload)
            } catch (error) {
                //console.log('error', error);
            }
            loader.hide()
        },
        async clearFilter() {
            this.country = null
            this.city = null
            this.industry = []
            const payload = {
                role_id: null,
                user_id: null,
                currentpage: 1,
                perPage: 10,
                search: this.search,
                filter: { filter: { filters: [] } }
            }
            await this.getAllJobsOrFilter(payload)
        },

        async filterJobs() {
            //// console.log("country", this.country);
            //// console.log("City", this.city);
            //console.log("industry", this.industry);
            let f = []

            if (this.industryOptions.length) {
                const filterIndustry = this.industryOptions.filter((ind) => this.industry.includes(ind.speciality_id)).map((ind) => {
                    //console.log("ind", ind);
                    const indFilter = {
                        "checkbox": ind.speciality,
                        "checkbox_id": ind.speciality_id,
                        "title": "industry"
                    }
                    f.push(indFilter)

                })
            }
            if (this.country) {
                f.push({ "checkbox": this.country?.country_name, "checkbox_id": this.country?.country_id, "title": "Country" })
            }
            if (this.country && this.city) {
                // f.push({ "checkbox": this.country?.country_name, "checkbox_id": this.country?.country_id, "title": "Country" })
                f.push({ "city_id": this.city?.city_id, "city_name": this.city?.label, "country_name": this.country?.country_name })
            }
            if (this.typeOfJob !== null) {
                // f.push({ "checkbox": this.country?.country_name, "checkbox_id": this.country?.country_id, "title": "Country" })
                f.push({ "job_type": this.typeOfJob.job_type_title, "title": "job_type", "checkbox_id": this.typeOfJob.job_type_title })
            }

            console.log("FilterPayload", f);
            this.filters = f
            const loader = this.$loading.show()
            try {
                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: 1,
                    perPage: 10,
                    search: '',
                    filter: { filter: { filters: f } }
                }
                await this.getAllJobsOrFilter(payload)
                this.homeFilter = false

            } catch (error) {
                console.log(error);
            }

            loader.hide()
        },
        async jobSearch(s) {
            event.preventDefault();
            // console.log("search", s.target.value);
            // this.search = s.target.value
            const payload = {
                role_id: null,
                user_id: null,
                currentpage: 1,
                perPage: 10,
                // search: s.target.value,
                sortBy: this.SortBy,
                search: this.search,
                filter: { filter: { filters: [] } }
            }
            await this.getAllJobsOrFilter(payload)

        },
        infinitScrollForJobs(payload) {
            this.infiniteScrollLoader = true
            CandidateService.GetAllJobsOrFilter(payload)
                .then((res) => {
                    //console.log("all jobs", res.data);
                    //console.log("all jobs", res.data.data.data);
                    this.totalJobs = res.data.data.total
                    //console.log("totalJobs", this.totalJobs);
                    const allJobs = res.data.data.data
                    let filterJob = []
                    if (allJobs.length) {
                        allJobs.map(async (aj) => {
                            const apJob = {
                                job_id: aj.job_id,
                                company_logo_path: aj.company_logo_path,
                                Job_title: aj.job_title,
                                company: aj.company_long_name,
                                jobType: aj.job_type,
                                designation: "Senior level",
                                country: aj.country,
                                city: aj.city_name,
                                postedAt: "Posted " + convertToTimeSince(aj.job_start_date),
                                jobDescription: aj.job_description.split(/[.•]/).filter((item, i) => item !== "" && item !== "\n" && i < 3),
                                numberOfOpenings: aj.vacancy,
                                currency: aj.currency_code,
                                job_salary_range_from: await formattedNumber(aj.job_salary_range_from),
                                job_salary_range_to: await formattedNumber(aj.job_salary_range_to),
                                gender: aj.gender === "Female" ? "F" : "M"
                            }
                            this.userJobs.push(apJob)
                        })
                    }
                    else {
                        this.isAllJobsFetched = true;

                    }

                    this.infiniteScrollLoader = false
                })
                .catch((error) => {
                    this.infiniteScrollLoader = false
                    //console.log(error);
                })
        },
        handleScroll(e) {
            console.log("offsetHeight", e.target.offsetHeight);
            console.log("scrollHeight", e.target.scrollHeight);
            console.log("e.target.scrollTop", e.target.scrollTop);
            // console.log("scrollHeight - offsetHeight", e.target.scrollHeight - e.target.offsetHeight);
            console.log("this.userJobs.length ", this.userJobs.length);
            console.log("this.totalJobs", this.totalJobs);
            console.log("this.userJobs.length < this.totalJobs", this.userJobs.length < this.totalJobs);
            console.log("e.target.scrollTop + e.target.offsetHeight", Math.round(e.target.scrollTop + e.target.offsetHeight + 5));
            const Scroll = (e.target.scrollHeight) <= Math.round(e.target.scrollTop + e.target.offsetHeight + 5)
            console.log(Scroll);
            if (this.isAllJobsFetched) {
                return
            }
            if (Scroll && !this.infiniteScrollLoader && (this.userJobs.length < this.totalJobs)) {
                //console.log("scrolled");
                this.currentpage = this.currentpage + 1

                const payload = {
                    role_id: null,
                    user_id: null,
                    currentpage: this.currentpage,
                    perPage: this.perPage,
                    sort: this.SortBy,
                    search: this.search,
                    filter: { filter: { filters: this.filters } }
                }
                //console.log(payload);
                //console.log("UserJobs", this.userJobs.length);
                //console.log("totalJobs", this.totalJobs);
                this.infinitScrollForJobs(payload)

            }
        },
        BottomSheetFilterToggler(v) {
            this.homeFilter = !this.homeFilter
        },
        handleClickOutside(e) {


            if (this.homeFilter) {
                // console.log('e.target', e.target);
                // console.log('e.target', e.target.className);
                const constbtContent = document.getElementById('bt-content')
                const industry = document.getElementById('industry-label')
                const nav = document.getElementById('zc-nav')
                // console.log('constbtContent', constbtContent);
                // console.log('constbtContent.contains(e.target)', constbtContent.contains(industry));
                const btn = e.target.contains(constbtContent) || constbtContent.contains(e.target) || constbtContent.contains(industry)
                // console.log('btn', btn);
                if (e.target.className === 'zcBottomSheet' || !btn || nav.contains(e.target)) {
                    this.hideMenu()
                }
            }

        },
        hideMenu: function () {
            this.homeFilter = false
        },
    },
    mounted() {
        const jobsDiv = document.getElementById('h-userjob')
        const mainSection = document.getElementById('main-section')
        console.log("window.innerWidth", window.innerWidth <= 992);
        console.log("mainSection::Home", mainSection);
        if (window.innerWidth <= 992) {
            mainSection.addEventListener('scroll', this.handleScroll);
        } else {
            jobsDiv.addEventListener('scroll', this.handleScroll);

        }

        window.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        if (window.innerWidth <= 992) {
            const mainSection = document.getElementById('main-section')
            mainSection.removeEventListener('scroll', this.handleScroll);
        } else {
            const jobsDiv = document.getElementById('h-userjob')
            jobsDiv.removeEventListener('scroll', this.handleScroll);
        }
        window.removeEventListener("click", this.handleClickOutside);
    },
    created() {
        // addEventListener("resize", (e) => {
        //     this.isMobileMenu = window.innerWidth <= 992
        // });
    },
    beforeMount() {
        this.isMobileMenu = window.innerWidth <= 992
        this.prefilledData()
    },
}
</script>

<style>
.search-container {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/zaajira-candidate/assets/icons/Pattern1.png');
    background-size: cover;
    /* Adjust as needed */
    background-position: center;
    /* Adjust as needed */
    color: white;
    /* Text color on top of the background */
}

.h-user-jobs {
    overflow: auto;
    height: 81vh;
}

.filter-icon {
    position: absolute;
    top: 14px;
    right: 28px;
}

.search-icon {
    position: absolute;
    top: 16px;
    left: 20px;
    opacity: .5;
}

a:hover {
    color: #000;
    text-decoration: none;
}

@media screen and (max-width:992px) {
    .h-user-jobs {
        overflow: visible;
        height: 100vh;
    }

    /* .h-user-jobs::-webkit-scrollbar {
        display: none !important;
    } */
}



@media screen and (max-width:360px) {

    .filter-icon {
        position: absolute;
        top: 14px;
        right: 5px;
    }

    .search-icon {
        left: 5px;
    }
}
</style>